<template>
    <v-footer height="auto" dark fixed width="auto">
        <v-row row class="text-sm-2">
            <v-col>
                &copy; {{copyright}}
            </v-col>
            <v-col>
                
                <router-link :to="{ name: 'privacy'}" target="_blank">
                    Privacy Statement
                </router-link>
            </v-col>
            <v-col right align="right">
                API: {{apiUrl}} Version: {{version}} ({{environment}})
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    export default {
        name: 'FooterBar', 
        data: () => ({
            apiUrl: process.env.VUE_APP_API_URL,
            version: process.env.VUE_APP_VERSION,
            environment: process.env.VUE_APP_ENVIRONMENT,
        }),

        computed: {
            // a computed getter
            copyright: function () {
                var currentTime = new Date()
                return `${currentTime.getFullYear()} All Rights Reserved`;
            }
        }
    }
</script>