import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
// eslint-disable-next-line
const delay = ms => new Promise(res => setTimeout(res, ms));

let exports = {};

let secureHeaders = async (headers) => {
    let authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;
    if (authTokens) {
        const decodedToken = jwt_decode(authTokens.accessToken)
        const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

        if (isExpired) {
            await refreshToken('expired')
            authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;
            if (authTokens === undefined) {
                console.log('*** Failed to Refreshed Token ***');
            }
        }
        headers.append('Authorization', 'Bearer ' + authTokens.accessToken);
    }
    else {
        console.log("*** No Token ***");
    }
    return headers;
}

let getHeaders = async () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    //headers.forEach(header => console.log(header));
    return headers;
}

let refreshToken = (reason) => {
    //console.log(`*** Refresh Token: ${reason} ***`);

    let authTokens = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    
    return fetch(`${process.env.VUE_APP_API_URL}/api/authenticate/refresh-token`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify({ 'accessToken': authTokens.accessToken, 'refreshToken': authTokens.refreshToken })
        })
    .then(r => r.json())
    .then(json => {
        let authTokens = json;
        //console.log("json - authTokens: ", json);

        if (authTokens) {
            //console.log("*** Valid authTokens ***");
            try {
                const decodedToken = jwt_decode(authTokens.accessToken)
                // eslint-disable-next-line
                let examiner = {
                    id: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                    firstName: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"],
                    lastName: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
                    uniqueName: decodedToken["UniqueName"],
                }

                //console.log(`*** Examiner: ${examiner.firstName} ${examiner.lastName} ***`);

                const isExpired = dayjs.unix(examiner.exp).diff(dayjs()) < 1;

                if (isExpired) {
                    console.log('*** Got already expired Auth Tokens ***');
                }
                else {
                    localStorage.setItem('authTokens', JSON.stringify(json));
                    //console.log(`*** ${reason} - Set Refreshed Auth Tokens ***`);
                }
            }
            catch (ex) {
                console.log(`*** ${reason} - Failed to refresh Auth Tokens: ${ex} ***`);
            }
        }
        else {
            console.log(`*** ${reason} - Didn't get valid Auth Tokens ***`);
        }
        
    });
}

let secureAPICall = async (url, searchParams) => {
    let headers = await secureHeaders(await getHeaders());
    url = searchParams === undefined ? `${process.env.VUE_APP_API_URL}${url}` : `${process.env.VUE_APP_API_URL}${url}?${searchParams}`;

    let data = null;
    let response = await fetch(url, { headers })
    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(url, { headers });

        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    data = await response.json()
    return { response, data }
}
let insecureAPICall = async (url, searchParams) => {
    let headers = await getHeaders();
    url = searchParams === undefined ? `${process.env.VUE_APP_API_URL}${url}` : `${process.env.VUE_APP_API_URL}${url}?${searchParams}`;
    let data = null;
    let response = await fetch(url, { headers })
    data = await response.json()
    return { response, data }
}

// Secured API Calls - Admin
exports.examiners = async function (searchTerm) {
    let { response, data } = await secureAPICall(`/api/examiners/${searchTerm}`);
    return { response, data };
};
exports.setSubcribed = async function (examinerId, subscribed) {
    let headers = await secureHeaders(await getHeaders());

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/subscription/${examinerId}/${subscribed}`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
        })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/subscription/${examinerId}/${subscribed}`,
            {
                method: 'POST', // or 'PUT'
                headers: headers,
            })

        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    //let data = await response.json()
    return { response: response, data: null }
};

// Secured API Calls - Examiner
exports.examinerSettings = async function () {
    let { response, data } = await secureAPICall(`/api/examiner/settings`);
    return { response, data };
};
exports.examinerAirports = async function () {
    let { response, data } = await secureAPICall(`/api/examiner/airports`);
    return { response, data };
};
exports.examinerAvailability = async function ({ start, end }) {
    console.log("examinerAvailability")

    let searchParams = new URLSearchParams(
        {
            start: start.date,
            end: end.date
        });
    let { response, data } = await secureAPICall(`/api/examiner/available?${searchParams}`);
    return { response, data };
};
exports.addExaminerAvailability = async function ({ start, end, airportIDs }) {
    const availabilityRequest =
    {
        start: start,
        end: end,
        airportIDs: airportIDs
    };
    
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available`, {
        method: 'POST', // or 'PUT'
        headers: headers,
        body: JSON.stringify(availabilityRequest),
    })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available`, {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(availabilityRequest),
        })

        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    let data = await response.json()
    return { response, data }
};
exports.removeExaminerAvailability = async function (availabilityID) {
    console.log(`removeExaminerAvailability: ${availabilityID}`)
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available/${availabilityID}`, {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(availabilityRequest),
    })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available/${availabilityID}`, {
            method: 'DELETE', // or 'PUT'
            headers: headers,
            //body: JSON.stringify(availabilityRequest),
        })
    }
    return response
};
exports.updateExaminerAvailability = async function (examinerID, { availabilityID,  start, end, airportIDs }) {
    const updateAvailabilityRequest =
    {
        availabilityID: availabilityID,
        start: start,
        end: end,
        airportIDs: airportIDs
    };


    console.log("Update Availability Request: ", updateAvailabilityRequest)


    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerID}/available`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(updateAvailabilityRequest),
        })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerID}/available`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(updateAvailabilityRequest),
        })
    }
    let data = await response.json()
    return { response, data }
};
exports.updateEvent = async function (examinerID, { eventID, airportID, start, end }) {
    const updateEventRequest =
    {
        currentUrl: window.location.href,
        eventID: eventID,
        airportID: airportID,
        start: start,
        end: end,
    };
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/event`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(updateEventRequest),
    })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/event`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(updateEventRequest),
        })
    }
    let data = await response.json()
    return { response, data }
};
exports.removeEvent = async function (eventID) {
    //console.log(`removeEvent: ${eventID}`)
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/event/${eventID}`, {
        method: 'DELETE',
        headers: headers,
        //body: JSON.stringify(availabilityRequest),
    })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/event/${eventID}`, {
            method: 'DELETE', // or 'PUT'
            headers: headers,
            //body: JSON.stringify(availabilityRequest),
        })
    }
    return response
};
exports.addExaminerAirport = async function ({ airportID }) {
    const addAirportRequest = {
        airportID: airportID
    };
    console.log(addAirportRequest)
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/airport`, {
        method: 'POST', // or 'PUT'
        headers: headers,
        body: JSON.stringify(addAirportRequest),
    })
    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/airport`, {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(addAirportRequest),
        })

        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    let data = await response.json()
    return { response, data }
};
exports.removeExaminerAirport = async function ({ airportID }) {
    console.log('Remove Examiner\'s Airport');

    let headers = await secureHeaders(await getHeaders());

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/airport/${airportID}`,
        {
            method: 'DELETE',
            headers: headers,
        })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/airport/${airportID}`,
            {
                method: 'DELETE',
                headers: headers,
            })


        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    let data = await response.json()
    return { response, data }
};
exports.examiner = async function () {
    let { response, data } = await secureAPICall(`/api/examiner`);
    return { response, data };
};
exports.examinerEvents = async function ({ start, end }) {
    //console.log(`*********** Start ${start.date} and End Date ${end.date}`)
    let searchParams = new URLSearchParams(
        {
            start: start.date,
            end: end.date
        });

    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/events?${searchParams}`,
        {
            method: 'GET',
            headers: headers,
        })

    if (response.status === 401) {
        await refreshToken('Set Function Settings request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/events?${searchParams}`,
            {
                method: 'GET',
                headers: headers,
            })


        if (response.status !== 201) {
            console.log("*** Status NOT 201: *** ");
            return { response: response, data: null };
        }
    }
    let data = await response.json()

    //console.log('*** Response: ', response);
    //console.log('*** Data: ', data);
    return { response, data }
};
exports.examinerAvailable = async function ({ start, end }) {
    //console.log(`Examiner Available Start ${start.date} and End Date ${end.date}`)
    let searchParams = new URLSearchParams(
        {
            start: start.date,
            end: end.date
        });

    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available?${searchParams}`,
        {
            method: 'GET',
            headers: headers,
        })

    if (response.status === 401) {
        await refreshToken('Set Function Settings request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/available?${searchParams}`,
            {
                method: 'GET',
                headers: headers,
            })


        if (response.status !== 201) {
            console.log("*** Status NOT 201: *** ");
            return { response: response, data: null };
        }
    }
    let data = await response.json()

    return { response, data }
};
exports.examinerFunctions = async function () {
    let { response, data } = await secureAPICall(`/api/examiner/functions`);
    return { response, data };
};
exports.examinerFunctionSetting = async function () {
    let { response, data } = await secureAPICall(`/api/examiner/function/settings`);
    return { response, data };
};
exports.addFunctionSettings = async function (func) {
    //console.log('Add Function Setting', func);
    let functionSettingsRequest = {
        functionCode: func.functionCode,
        assignedTime: func.assignedTime,
        content: func.content,
    };

    let headers = await secureHeaders(await getHeaders());

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/function/settings`,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(functionSettingsRequest),
        })
    if (response.status === 401) {
        await refreshToken('Set Function Settings request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/function/settings`,
            {
                method: 'POST', // or 'PUT'
                headers: headers,
                body: JSON.stringify(functionSettingsRequest),
            })


        if (response.status !== 201) {
            console.log("*** Status NOT 201: *** ");
            return { response: response, data: null };
        }
    }
    //console.log('*** Response: ', response);
    let data = await response.json()
    //console.log('*** Data: ', data);
    return { response, data }
};
exports.updateExaminer = async function (examinerProfile) {
    //console.log('Update Examiner Profile', examinerProfile);
    //console.log('Update defaultStartTime', examinerProfile.defaultStartTime);
    //console.log('Update defaultEndTime', examinerProfile.defaultEndTime);
    
    let headers = await secureHeaders(await getHeaders());

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner`,
        {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(examinerProfile),
        })
    if (response.status === 401) {
        await refreshToken('Set Function Settings request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner`,
            {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(examinerProfile),
            })


        if (response.status !== 201) {
            console.log("*** Status NOT 201: *** ");
            return { response: response, data: null };
        }
    }
    //console.log('*** Updated Examiner Profile Response: ', response);
    let data = await response.json()
    //console.log('*** Updated Examiner Profile Data: ', data);
    return { response, data }
};

//Public API Calls
exports.airports = async function () {
    //console.log('*** exports.airports ***');
    return await insecureAPICall(`/api/airports/all`);
    //return { response: null, data: null };
};
exports.examinerFunctionsByID = async function (examinerId) {
    let { response, data } = await insecureAPICall(`/api/examiner/${examinerId}/functions`);
    return { response, data };
};
exports.examinerAirportsByID = async function (examinerId) {
    let { response, data } = await insecureAPICall(`/api/examiner/${examinerId}/airports`);
    return { response, data };
};
exports.examinerByIDAllAvailability = async function (examinerId) {
    
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/available/all`, {
        method: 'GET',
        headers: headers,
        //body: JSON.stringify({ 'start': start.date, 'end': end.date, 'airportIDs': [''] })
    })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.checkAvailability = async function (examinerId, date, functionCode) {
    //console.log("checkAvailability: ", { date })
    let searchParams = new URLSearchParams({
        date: date,
        functionCode: functionCode
    });

    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/availability/check?${searchParams}`, {
        method: 'GET',
        headers: headers,
        //body: JSON.stringify({ 'start': start.date, 'end': end.date, 'airportIDs': [''] })
    })
    //console.log("OK: ", response.ok)
    return response
};
exports.examinerAvailabilityByDate = async function (examinerId, date) {
    //console.log("examinerAvailabilityByDate", date)
    let params = new URLSearchParams(
    {
        date: date,
    });

    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/available/date?${params}`,
        {
            method: 'GET',
            headers: headers,
        })
        .catch(err => {
            console.error(err)
            return null
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};

exports.examinerAvailabilityByID = async function (examinerId, { start, end }) {
    //console.log("examinerAvailabilityByID", { start, end })
    let searchParams = new URLSearchParams(
        {
            start: start.date,
            end: end.date
        });
    
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/available?${searchParams}`,
        {
            method: 'GET',
            headers: headers,
            //body: JSON.stringify({ 'start': start.date, 'end': end.date, 'airportIDs': [''] })
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.login = async (email, password) => {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return fetch(`${process.env.VUE_APP_API_URL}/api/authenticate/login`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ 'email': email, 'password': password })
    })
        .then(r => r.json())
        .then(json => {
            //console.log("**** Logged In Sucessfully ****");
            try {
                let decodedToken = jwt_decode(json.accessToken);
                //console.log("decodedToken: ", decodedToken);

                let roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                
                if (roles !== undefined && Array.isArray(roles)) {
                    if (roles.some(e => e === 'Admin')) {
                        //console.log("Admin", decodedToken);
                    }
                    if (roles.some(e => e === 'Examiner')) {
                        //console.log("Examiner", decodedToken);
                    }
                }
                
                localStorage.setItem('authTokens', JSON.stringify(json))
                return { decodedToken, roles };

            }
            catch (ex) {
                console.error("Token Parsing Probably Failed: ");
                console.error('Error getting available:', ex);
                return null;

            }

        });
};
exports.addEvent = async function ({ examinerId, eventRequestModel }) {

    //console.log("*** POST addEvent *** ", eventRequestModel);
    let headers = await getHeaders();

    //console.log(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/event`);

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/event`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(eventRequestModel),
        })

    //console.log("*** Response: *** ", response);

    //console.log("*** Status: *** ", response.status);

    if (response.status !== 200) {
        console.log("*** Status NOT 200: *** ");

        return { response: response, data: null };
    }

    let data = await response.json()
    //console.log("Add Event", data);
    return { response, data }
};
exports.getCheckEvent = async function ({ examinerId, eventRequestModel }) {
    //console.log("*** POST getCheckEvent *** ", eventRequestModel);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/event/check`,
    {
        method: 'POST', // or 'PUT'
        headers: headers,
        body: JSON.stringify(eventRequestModel),
    })

    //console.log("*** Response: *** ", response);
    //console.log("*** Status: *** ", response.status);

    if (response.status !== 200) {
        console.log("*** Status NOT 200: *** ");
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};


exports.functions = async function () {
    let { response, data } = await insecureAPICall(`/api/functions`);
    return { response, data };
};
exports.search = async function (examinersRequestDTO) {
    //console.log('search - examinersRequestDTO: ', examinersRequestDTO); 
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiners/search`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(examinersRequestDTO),
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.searchExaminerByBounds = async function (bounds, functionCode) {
    let url = `${process.env.VUE_APP_API_URL}/api/examiners/search/`
    if (functionCode !== null) {
        //console.log("functionCode: ", functionCode)
        url = `${url}${functionCode}`
    }
    else {
        //console.log("functionCode is null ")
    }
    
    let headers = await getHeaders();
    //console.log("url: ", url)
    let response = await fetch(url,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(bounds)
        })

    //console.log("response: ", response)

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.searchExaminersByAirport = async function (airportID) {
    //console.log('Search Airport: ', airportID);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiners/airport/${airportID}`,
    {
        method: 'GET',
        headers: headers,
    })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.searchExaminer = async function (examinerID) {
    console.log('Search Examiner: ', examinerID);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerID}`,
        {
            method: 'GET',
            headers: headers,
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.searchExaminersByFunction = async function (functionCode) {
    //console.log('Search Examiners by Function: ', functionCode);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiners/function/${functionCode}`,
        {
            method: 'GET',
            headers: headers,
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.mapSearch = async function (examinersRequestDTO) {
    //console.log('Map Search - examinersRequestDTO: ', examinersRequestDTO);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiners/mapSearch`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(examinersRequestDTO),
        })

    if (response.status !== 200) {
        return { response: response, data: null };
    }
    let data = await response.json()
    return { response, data }
};
exports.queryFunctions = async function (examinersRequestDTO) {
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/functions/queryFunctions`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(examinersRequestDTO),
        })

    let data = await response.json()
    return { response, data }
};
exports.allFunctions = async function () {
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/functions/all`,
        {
            method: 'GET',
            headers: headers,
        })

    let data = await response.json()
    return { response, data }
};
exports.allExaminers = async function () {
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiners/all`,
        {
            method: 'GET',
            headers: headers,
        })

    let data = await response.json()
    //console.log(data)
    return { response, data }
};
exports.allAirports = async function () {
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/airports/all`,
        {
            method: 'GET',
            headers: headers,
        })

    let data = await response.json()
    //console.log(data)
    return { response, data }
};
exports.qrcode = async function (uri) {
    let headers = await getHeaders();
    let searchParams = new URLSearchParams(
        {
            uri: uri
        });
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/util/qrcode?${searchParams}`,
        {
            method: 'GET',
            headers: headers,
        })
    return await response.blob()
};
exports.setAvatar = async function (file) {
    var headers = new Headers();
    headers.append("accept", "*/*");

    headers = await secureHeaders(headers);

    var formdata = new FormData();
    formdata.append("avatarFile", file, file.name);

    var requestOptions = {
        method: 'POST',
        headers: headers,
        body: formdata,
        redirect: 'follow'
    };
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/avatar`, requestOptions);
    return { response: response }
};
exports.getAvatar = async function (examinerId) {
    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/avatar`,
        {
            method: 'GET',
            headers: headers,
        })
    return await response.blob()
};
exports.setBiography = async function (biography) {
    console.log("Set biography: ", biography)

    let headers = await secureHeaders(await getHeaders());
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/biography`,
        {
            method: 'POST', // or 'PUT'
            headers: headers,
            body: JSON.stringify(biography),
        })

    if (response.status === 401) {
        await refreshToken('Get Examiners request failed');
        //retry the request
        response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/biography`,
            {
                method: 'POST', // or 'PUT'
                headers: headers,
                body: JSON.stringify(biography),
            })

        if (response.status !== 200) {
            return { response: response, data: null };
        }
    }
    let data = await response.json()
    return { response, data }
};
exports.getBiography = async function (examinerId) {
    let headers = await getHeaders();
    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/examiner/${examinerId}/biography`,
        {
            method: 'GET',
            headers: headers,
        })

    console.log(response)
    let data = await response.json()
    return { response, data }
};
exports.getEventWithToken = async function (token) {
    //console.log("*** getEventWithToken *** ", token);
    console.log(`${process.env.VUE_APP_API_URL}/api/event?access_token=${token}`);
    let headers = await getHeaders();

    let response = await fetch(`${process.env.VUE_APP_API_URL}/api/event?access_token=${token}`,
    {
        method: 'GET', 
        headers: headers,
    })

    //console.log("*** Response: *** ", response);
    //console.log("*** Status: *** ", response.status);

    if (response.status !== 200) {
        console.log("*** Status NOT 200: *** ");
        return null;
    }

    return await response.json()
    
};

export default exports;